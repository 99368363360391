<template>
  <div class='bread_container' id="bread_container">
    <!-- <span @click="handleLefeMenu" class="bars">
      <i class="el-icon-s-fold"></i>
    </span> -->
    <!-- <el-breadcrumb class="breadcrumb" separator="/">
      <el-breadcrumb-item
        v-for='(name, index) in matchedArr'
        :key='index'
      >
         {{ name }}
      </el-breadcrumb-item>
    </el-breadcrumb> -->
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  props: {
    // 自定义面包屑名称
    matched: {
      default: null
    }
  },
  created () {
  },
  computed: {
    matchedArr () {
      if (this.matched) return this.matched
      let temp = []
      let temps = []
      this.$route.matched.filter((item) => {
        if (Array.isArray(item.meta)) {
          item.meta.forEach(element => {
            const title = element.title
            temp.push(title)
          })
        }
        if (item.name) {
          const name = item.name
          temp.push(name)
        }
      })
      temp.filter((item) => {
        if (!temps.includes(item)) {
          temps.push(item)
        }
      })
      return temps
    }
  },
  methods: {
    // handleLefeMenu () {
    //   this.$store.dispatch('setLeftCollapse') // 折叠菜单
    //   this.$store.dispatch('handleLeftMenu') // 改变菜单宽度 200->60/60-200
    // }
  }
}
</script>

<style lang='less' scoped>
.bread_container {
  width: 100%;
  .bars {
    float: left;
    margin: 5px 10px;
    cursor: pointer;
  }
  .breadcrumb {
    height: 30px;
    line-height: 30px;
    margin-bottom: 16px;
    .breadbutton {
      float:left;
      margin:4px 5px 0 0;
    }
  }
}
</style>
