const userRoutes = [
  {
    path: '/customerManage',
    name: '客户管理',
    meta: {
      permissionId: 7
    },
    component: () => import("@/pages/user/customerManage/index.vue"),
  },
  {
    path: '/customerManage/detail',
    name: '客户管理详情',
    meta: {
      permissionId: 7
    },
    component: () => import("@/pages/user/customerManage/detail.vue"),
  },
  {
    path: '/authAudit',
    name: '认证审核',
    meta: {
      permissionId: 8
    },
    component: () => import("@/pages/user/authAudit/index.vue"),
  },
  {
    path: '/authAudit/detail',
    name: '认证审核详情',
    meta: {
      permissionId: 8
    },
    component: () => import("@/pages/user/authAudit/detail.vue"),
  },
  {
    path: '/customerAlter',
    name: '客户变更',
    meta: {
      permissionId: 9
    },
    component: () => import("@/pages/user/customerAlter/index.vue"),
  },
  {
    path: '/customerAlter/detail',
    name: '客户变更详情',
    meta: {
      permissionId: 9
    },
    component: () => import("@/pages/user/customerAlter/detail.vue"),
  },
  {
    path: '/staffManage',
    name: '员工管理',
    meta: {
      permissionId: 10
    },
    component: () => import("@/pages/user/staffManage/index.vue"),
  },
]
export default userRoutes