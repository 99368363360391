import Vue from 'vue'
import App from './App.vue'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './utils/element.js'
import router from "./router";
import store from "./store";
import Mixins from "./mixins";
import clickoutside from "./directive/clickoutside";
import VueRouter from 'vue-router'
Vue.directive("clickoutside", clickoutside);
Vue.mixin(Mixins);

const original = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return original.call(this, location).catch(err => err)
}

// 新增push方法-跳转路由时保留url上的参数
// router.pushUrl = (location) => {
//   const str = window.location.href.split("?")[1] || "";
//   const newlocation = `${location}?${str}`;
//   router.push(newlocation);
// };
Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
export default app