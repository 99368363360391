import Vue from "vue";
import mask from "./index.vue"; // 引入loading实例
const Mask = Vue.extend(mask);
// 返回loadingService服务状态的实例  Loading.service()方式调用
export const Loading = (function() {
  let Service = function() {
    let divbox;
    let loadingMask;
    this.show = function() {
      if (document.querySelector('.myLoading-box-mask')) return
      divbox = document.createElement("div");
      divbox.className = 'myLoading-box-mask'
      let child = document.createElement("div");
      divbox.appendChild(child);
      document.body.appendChild(divbox);
      loadingMask = new Mask();
      loadingMask.visible = true;
      loadingMask.$mount(child);
    };
    this.hide = function() {
      loadingMask.visible = false;
      if (divbox) {
        divbox.style.opacity = 0
        document.body.removeChild(divbox);
        divbox = null
      }
    };
  };
  return new Service();
})();  // 自调用，这样return出来的就可以直接使用了，不用再运行一次