<template>
  <div class="drafts-detail-info">
    <el-row>
      <el-col v-for="(col, index) in info" :key="col.prop + index" :span="span">
        <label>{{ col.label }}：</label>
        <span v-if="col.type === 'enum'">{{ col.enums[data[col.prop]] || '-' }}</span>
        <span v-else-if="col.type === 'input'">
          <el-input size="mini" :value="data[col.prop]" disabled></el-input>
        </span>
        <span v-else-if="col.type === 'amount'">{{ data[col.prop] | filterMoney }}</span>
        <span v-else-if="col.type === 'date'">{{ dateFormat(data[col.prop], col.format) }}</span>
        <span v-else-if="col.type === 'image'">
          <drafts-detail-image v-for="item in data[col.prop]" type="text" @click="openPreview" :key="item.url" :src="item"></drafts-detail-image>
        </span>
        <span v-else-if="col.type === 'imageUrl'">
          <drafts-detail-image v-for="item in data[col.prop]" type="img" @click="openPreview" :key="item" :src="item"></drafts-detail-image>
        </span>
        <span v-else>{{ data[col.prop] || '-' }}</span>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="dialogVisible"></el-dialog>
  </div>
</template>
<script>
  import { filterMoney } from '@/utils/util'
  import moment from 'moment'
  export default {
    name: 'drafts-detail-info',
    props: {
      span: {
        default: 12,
        type: Number
      },
      data: {
        default: () => {},
        type: Object
      },
      info: {
        default: () => [],
        type: Array
      }
    },
    data() {
      return {
        dialogVisible: false,
      }
    },
    methods: {
      openPreview(){},
      dateFormat(value, format) {
      if (value) {
        return moment(value).format(format || 'YYYY-MM-DD HH:mm:ss')
      }
      return '-'
    }
    },
    filters: {
      filterMoney
    }
  }
</script>
<style lang="less">
.drafts-detail-info {
  .el-row {
    padding: 0 50px;
  }
  .el-col {
    margin-bottom: 18px;
    // height: 36px;
    line-height: 36px;
    font-size: 14px;
    display: flex;
    // overflow-x: hidden;
    >span {
      flex: 1;
      white-space: normal;
      word-break: break-all;
    }
    label {
      flex: none;
      display: inline-block;
      width: 250px;
      padding-right: 20px;
      text-align: right;
    }
  }
}
</style>