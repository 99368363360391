<template>
  <div class="pagination">
    <el-pagination
      v-if="pageTotal > 0"
      background
      :layout="paginations.layout"
      :total="pageTotal"
      :current-page="currentPage"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :page-size="pageSize"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'drafts-pagination',
  data () {
    return {
      paginations: {
        layout: 'total, sizes, prev, pager, next, jumper' // 翻页属性
      }
    }
  },
  props: {
    pageTotal: Number,
    currentPage: Number,
    pageSize: Number
  },
  created () {},
  mounted () {},
  methods: {
    // 上下分页 pageIndex
    handleCurrentChange (val) {
      this.$emit('change', {pageIndex: val})
    },
    handleSizeChange (val) {
      this.$emit('change', {pageSize: val})
    },
  }
}
</script>

<style lang="less" scoped>
.pagination {
  text-align: right;
  padding: 10px 18px;
  margin-top: 40px;
}
</style>
