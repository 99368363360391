import { mapGetters } from 'vuex'
import moment from 'moment'
import draftsTable from '../components/drafts-table'
import draftsPagination from '../components/pagination'
import CustomUpload from '@/components/upload/index.vue'
import DraftsSubTitle from '@/components/detail-info/title.vue'
import DraftsDetailTitle from '@/components/detail-info/sub-title.vue'
import DraftsDetailInfo from '@/components/detail-info/info.vue'
import DraftsDetailImage from '@/components/detail-info/image.vue'
import {logout} from '@/api/login'
export default {
  data () {
    return {
    }
  },
  computed: {
    // ...mapGetters(["tenantInfo"]),
    // ...mapGetters(["ispageReady"]),
    // ...mapGetters(["currentEnterprise"]),
    // ...mapGetters(["userInfo"]),
    ...mapGetters(["userInfo", 'ispageReady', 'currentEnterprise', 'tenantInfo']),
    curCompanyId() {
      return this.currentEnterprise.companyId
    }
  },
  components: {
    draftsTable,
    draftsPagination,
    CustomUpload,
    DraftsSubTitle,
    DraftsDetailTitle,
    DraftsDetailInfo,
    DraftsDetailImage
  },
  methods: {
    $EXPORT (data, headers) {
      const filename = (
        headers['content-disposition'] && 
        decodeURI(headers['content-disposition']?.split(';')[1].split('filename=')[1])
        )|| '下载文件.xlsx'
      /* 兼容ie内核，360浏览器的兼容模式 */
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        let blob = new Blob([data])
        window.navigator.msSaveOrOpenBlob(blob, filename)
      } else {
        /* 火狐谷歌的文件下载方式 */
        var blob = new Blob([data], {type: 'application/vnd.ms-excel;charset=utf-8'})
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(blob)
        downloadElement.href = href
        downloadElement.download = filename
        document.body.appendChild(downloadElement)
        downloadElement.click()
        document.body.removeChild(downloadElement)
        window.URL.revokeObjectURL(href)
      }
    },    
    userLogout() {
      const val = localStorage.getItem("authorization");
      logout({request: val}).then(res => {
        if (!res) return
        this.$message.success('操作成功')
        localStorage.removeItem("authorization");
        localStorage.removeItem("currentEnterprise");
        setTimeout(() => {
          this.$router.replace("/");
        }, 500)
      })
    },
    // getEnterpriseInfo (name = this.currentEnterprise) {
    //   let val = {}
    //   this.enterpriseList.map(item => {
    //     if (item.companyName === name) {
    //       val = item
    //     }
    //   })
    //   return val
    // },
    formatList (key) {
      return {
        date: (value, format) => { 
          return moment(value).format(format)
        }
      }[key]
    },
  },
}