<template>
  <div id="app" v-if="showPage && isReady">
    <router-view v-if="$route.meta.disableLayout" />
    <Layout v-else>
      <router-view />
    </Layout>
  </div>
</template>

<script>
import { Layout } from "./layout"; // 页面整体布局
import { setTheme, ysfHost } from "./utils/util";
import { getTenantByCode } from "@/api/common";
export default {
  name: "App",
  data() {
    return {
      showPage: false,
      isReady: false,
    };
  },
  components: {
    Layout,
  },
  created() {
    this.$router.onReady(() => {
      this.showPage = true;
    });
    // 获取配置租户信息
    getTenantByCode().then((res) => {
      if (!res) return;
      const hostName = `${window.location.origin}${
        process.env.NODE_ENV === "development" ? "/api" : "/api"
      }`;
      res.hostName = hostName;
      // 存储信息
      console.log(res);
      this.$store.commit("setTenantInfo", res);
      // 修改主题色
      setTheme({
        "--main-color": res.mainImageColor || "#1959af",
      });
      this.insert_cchc_components(res);
      // 设置页签标题
      document.title = res.platformShortName;
      // 修改favicon
      const faviconLink = document.querySelector("link[rel='icon']");
      faviconLink && (faviconLink.href = res.favicon);
      this.isReady = true;
    });
  },
  methods: {
    insert_cchc_components(val) {
      let script = document.createElement("script");
      let link = document.createElement("link");
      script.type = "text/javascript";
      link.type = "text/css";
      link.rel = "stylesheet";
      let head = document.getElementsByTagName("head")[0];
      // 插入共认登录js文件
      script.src = ysfHost + "/identifyPlatformBusiness1/static/js/index.js"; //测试环境
      // script.src = '/cloud/index.js'; // 本地联调
      head.appendChild(script);
      // 插入共认登录css文件
      link.href = ysfHost + "/identifyPlatformBusiness1/static/css/index.css"; //测试环境
      // link.href = '/cloud/index.css' // 本地联调
      head.appendChild(link);

      script.onload = script.onreadystatechange = function () {
        if (
          !this.readyState ||
          this.readyState == "loaded" ||
          this.readyState == "complete"
        ) {
          window.CCHC_YSF_Components.setOptions({
            mainColor: val.mainImageColor,
            // platformFlag: process.env.NODE_ENV === "development" ? "PYL1001" : "JPY1001",
            platformFlag: val.platformFlag || 'PYL1001',
          });
        }
        script.onload = script.onreadystatechange = null;
      };
    },
  },
};
</script>

<style lang="less">
@import "./styles/init.less";
@import "./styles/element.less";
</style>
