const loginRoutes = [
  {
    path: '/login',
    name: '登录',
    meta: {
      disableLayout: true
    },
    component: () => import("@/pages/login/index.vue"),
  },
  {
    path: '/register',
    name: '注册',
    meta: {
      disableLayout: true
    },
    component: () => import("@/pages/login/register.vue"),
  },
  {
    path: '/forgetPwd',
    name: '找回密码',
    meta: {
      disableLayout: true
    },
    component: () => import("@/pages/login/forgetPwd.vue"),
  },
  // 完善信息页面
  {
    path: '/completeInfo',
    name: '完善企业信息',
    meta: {
      disableMenu: true
    },
    component: () => import("@/pages/login/completeInfo.vue"),
  },
  
  // 提交完善信息-显示待审核状态
  {
    path: '/auditStatus',
    name: '状态',
    meta: {
      disableMenu: true
    },
    component: () => import("@/pages/login/status.vue"),
  },
  // 注册成功-状态页
  {
    path: '/registerSuccess',
    name: '注册成功',
    meta: {
      disableLayout: true
    },
    component: () => import("@/pages/login/registerSuccess.vue"),
  },
]
export default loginRoutes