const discountRoutes = [
  {
    path: '/discountForPrice',
    name: '贴现询价',
    meta: {
      permissionId: 1
    },
    component: () => import("@/pages/discount/forPrice/index.vue"),
  },
  {
    path: '/institutionPrice',
    name: '机构报价',
    meta: {
      permissionId: 2
    },
    component: () => import("@/pages/discount/institutionPrice/index.vue"),
  },
  {
    path: '/recordManage',
    name: '建档管理',
    meta: {
      permissionId: 3
    },
    component: () => import("@/pages/discount/recordManage/index.vue"),
  },
  {
    path: '/recordManage/detail',
    name: '建档查询',
    meta: {
      permissionId: 3
    },
    component: () => import("@/pages/discount/recordManage/detail.vue"),
  },
  {
    path: '/discountSearch',
    name: '贴现查询',
    meta: {
      permissionId: 4
    },
    component: () => import("@/pages/discount/search/index.vue"),
  },
  {
    path: '/discountSearch/detail',
    name: '贴现查询详情',
    meta: {
      permissionId: 4
    },
    component: () => import("@/pages/discount/search/detail.vue"),
  },
  {
    path: '/discountData',
    name: '贴现数据',
    meta: {
      permissionId: 11
    },
    component: () => import("@/pages/discount/data/index.vue"),
  },
  {
    path: '/discountData/detail',
    name: '贴现数据详情',
    meta: {
      permissionId: 11
    },
    component: () => import("@/pages/discount/search/detail.vue"),
  },
  {
    path: '/discountBill',
    name: '贴现对账',
    meta: {
      permissionId: 12
    },
    component: () => import("@/pages/discount/bill/index.vue"),
  },
  {
    path: '/discountBill/detail',
    name: '贴现对账详情',
    meta: {
      permissionId: 12
    },
    component: () => import("@/pages/discount/bill/detail.vue"),
  }
]
export default discountRoutes