import { requestGet, requestPost } from "@/utils/request";
// 判断是否登录
export const getUserToken = () => {
  return requestGet(
    "/home/page/getUserInfo",
    {},
    {
      showResponse: true,
    }
  );
};
// 登录
export const login = (data) => {
  return requestPost("/login/login", data);
};
// 退出登录
export const logout = (data) => {
  return requestPost("/login/logout", data);
};
// 修改密码
export const resetPassword = (data) => {
  return requestPost("/login/resetPassword", data);
};
// 企业用户注册
export const registerUser = (data) => {
  return requestPost("/register/registerUser", data);
};
// 获取图形验证码
export const getImgVerifyCode = () => {
  return requestGet("/general/getImgVerifyCode");
};
// 获取手机验证码
export const sendUserCode = (data) => {
  return requestPost("/general/sendUserCode", data);
};
// 查询用户下对应企业信息
export const queryCompany = (id) => {
  return requestGet("/register/queryCompany", { companyId: Number(id) });
};
// 完善企业信息
export const updateCompany = (data) => {
  return requestPost("/register/updateCompany", data);
};

// 云身份登录
export const cloudLogin = (data) => {
  return requestPost("/cloud/common/login", data);
};

// 云身份 授权完成-保存企业数据
export const cloudSaveCompany = (data) => {
  return requestPost("/cloud/commonSaveCompany", data);
};
