import {requestGet, requestPost} from '@/utils/request'
// 获取用户信息
export const getUserInfo= () => {
  return requestGet('/home/page/getUserInfo')
}
// 获取企业菜单权限
export const  getUserPermission = (id) => {
  return requestGet('/user/permission/getUserPermission',{companyId: id})
}

// 修改默认企业
export const updateDefaultCompany = (id) => {
  return requestPost('/home/page/updateDefaultCompany', {companyId: id})
}

// 变更用户信息
export const updateUser = (data) => {
  return requestPost('/userManager/updateUser', data)
}
