<template>
    <el-upload class="custom-upload-box" 
      action="" 
      :auto-upload="false"
      :show-file-list="false"
      :accept="accept" 
      :on-change="onChange"
      >
      <img v-if="fileUrl" :src="fileUrl"/>
      <el-button v-else size="small"><i class="el-icon-upload2"></i>{{text}}</el-button>
      <div slot="tip" class="el-upload__tip">
        <i v-if="fileUrl" @click="handleClear" class="el-icon-circle-close close"></i>
        {{tip}}
      </div>
    </el-upload>
</template>

<script>
import {uploadFile} from '@/api/common'
export default {
  name: 'custom-upload',
  model: {
    prop: 'fileUrl',
    event: 'change'
  },
  data () {
    return {
    }
  },
  props: {
    size: {
      default: 10,
      type: Number
    },
    fileUrl: {
      type: String,
      default: ''
    },
    text: {
      default: '上传文件',
      type: String
    },
    accept:{
      default: '.jpg,.png,.jpeg',
      type: String
    },
    tip: {
      default: '支持 .jpg .png .jpeg格式，大小不超过10M',
      type: String
    }
  },
  methods: {
    handleClear() {
      this.$emit('change', '')
    },
    onChange(file) {
      console.log(file)
      if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file.raw.type)) {
        return this.$message.warning('支持 .jpg .png .jpeg格式，大小不超过10M')
      }
      const maxSize = this.size*1024*1024 
      if (file.size > maxSize) {
        this.$message.warning('请上传文件大小在' + this.size +'M内的图片')
        return 
      }
      uploadFile({file: file.raw}).then(res => {
        if (!res) return
        res.relativePath = this.tenantInfo.hostName + res.relativePath
        this.$emit('change', res.relativePath)
        this.$emit('success', res)
      })
    },
  },
}
</script>

<style lang="less">
  .custom-upload-box {
    display: flex;
    flex-wrap: wrap;
    .close {
      font-size: 16px;
      cursor: pointer;
      position: relative;
      top: 2px;
      &:hover {
        color: var(--main-color);
      }
    }
    img {
      width: 200px
    }
    .el-upload__tip {
      margin-top: 0;
      margin-left: 20px;
    }
    .el-upload-list{
      text-align: left;
      margin-bottom: 10px;
      .el-upload-list__item:first-child {
        margin-top: 0;
      }
    }
  }
</style>