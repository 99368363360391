<template>
  <div class="drafts-detail-title">
    <a @click="$router.back()"><i class="el-icon-back"></i> 返回</a>
    <span>{{text}}</span>
  </div>
</template>
<script>
  export default {
    name: 'drafts-detail-title',
    props: {
      text: {
        type: String,
        default: "查看详情"
      }
    }
  }
</script>
<style lang="less">
.drafts-detail-title {
  list-style: 30px;
  font-size: 16px;
  line-height: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  font-size: 18px;
  i::before {
    color: var(--main-color);
  }
  span {
    padding-left: 30px;
  }
}
</style>