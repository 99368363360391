import axios from 'axios'
import { Message } from 'element-ui'
import {Loading} from '@/components/loading/index.js'
import router from '../router'
import _this from '@/main.js'

axios.defaults.timeout = 10000
axios.defaults.withCredentials = true
// axios.defaults.baseURL = process.env.NODE_ENV  === 'development' ? '' : '/api', // api的base_url
axios.defaults.baseURL = '/api', // api的base_url
// request拦截器
axios.interceptors.request.use(
  config => {
    Loading.show() // 全局loading是否启用
    let authorization = localStorage.getItem('authorization') // 登录信息
     if (authorization) {
      config.headers = {
        'authorization': authorization // 携带权限参数
      }
    }
    // 解决IE浏览器get接口数据缓存问题
    if (!!window.ActiveXObject || 'ActiveXObject' in window) {
      if (config.url.indexOf('?') === -1) {
        config.url += `?ts=${(new Date()).valueOf()}`
      } else {
        config.url += `&ts=${(new Date()).valueOf()}`
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// respone拦截器
axios.interceptors.response.use(
  response => {
    Loading.hide()
    /**
    * code:200,接口正常返回;
    */
   const res = response.data
    // response.config.showResponse 决定对返回结果不做处理，直接返回
    if (response.config.showResponse) {
      return response
    } else if (res.code != 200 && !response.config.showResponse) {
      // 1002-登录失效
      // 3002-用户不存在或被禁用
      const forbidCode = ['1001', '1002', '3004']
      if (forbidCode.includes(res.code)) {
        Message({
          message: res.msg,
          type: 'error',
        })
        localStorage.removeItem("authorization");
        localStorage.removeItem("currentEnterprise");
        if (_this.$route.path !== '/login') {
          router.replace('/')
        }
        return false
      }
      Message({
        message: res.msg,
        type: 'error',
        duration: 5 * 1000
      })
      return false
    } else {
      // res.code === 200,正常返回数据
      return response.data.result || true
    }
  },
  error => {
    Loading.hide()
    const codeMessage = {
      200: '服务器成功返回请求的数据。',
      201: '新建或修改数据成功。',
      202: '一个请求已经进入后台排队（异步任务）。',
      204: '删除数据成功。',
      400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
      401: '用户没有权限。',
      403: '用户得到授权，但是访问是被禁止的。',
      404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
      406: '请求的格式不可得。',
      410: '请求的资源被永久删除，且不会再得到的。',
      422: '当创建一个对象时，发生一个验证错误。',
      500: '服务器发生错误，请检查服务器。',
      502: '网关错误。',
      503: '服务不可用，服务器暂时过载或维护。',
      504: '网关超时。',
    };
    const { response } = error;
    if (response && response.status) {
      const errorText = codeMessage[response.status] || response.statusText;
      const { status } = response;
      Message({
        message: errorText,
        type: 'error',
        duration: 5 * 1000
      })
      if (status === 401) {
        localStorage.removeItem("authorization");
        localStorage.removeItem("currentEnterprise");
        if (_this.$route.path !== '/login') {
          router.replace('/')
        }
      }
    } else if (!response) {
      Message({
        message: '您的网络发生异常，无法连接服务器',
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

// 创建axios实例
// let service = axios.create({
//   baseURL: process.env.NODE_ENV  === 'development' ? '' : '/api', // api的base_url
//   // baseURL: '', // api的base_url
//   timeout: 10000, // 请求超时时间
// })
// form-data参数转换格式
// const convertData = (data) => { 
//   const formData = new FormData()
//   Object.keys(data).map(key => {
//     if (key === 'fileDetails' && data[key].constructor === Array) {
//       // 仅针对batchUploadFileToZip接口
//       formData.append(key, new Blob([JSON.stringify(data[key])], {type: 'application/json'}))
//     } else if (data[key].constructor === Array) {
//       data[key].map(item => { 
//         formData.append(key, item)
//       })
//     } else if (data[key].constructor === Object) {
//       formData.append(key, new Blob([JSON.stringify(data[key])], {type: 'application/json'}))
//     } else {
//       formData.append(key, data[key])
//     }
//   })
//   return formData
// }
// form-data格式请求
// export const formDataPost = (url, data) => {
//   const formData = convertData(data)
//   return axios({
//     url,
//     method: 'post',
//     headers: {
//       'Content-Type': 'multipart/form-data'
//     },
//     data: formData
//   }).catch(() => {})
// }

export const requestGet = (url, params, defines = {}) => {
    return axios({
      method: 'get',
      url,
      params,
      ...defines
    }).catch(() => {})
}

export const requestPost = (url, data, defines = {}) => {
    return axios({
      method: 'post',
      url,
      data,
      ...defines
    }).catch(() => {})
}

export const requestFormData = (url, data, defines= {}) => {
  const formData = new FormData()
  Object.keys(data).map(key => {
    formData.append(key, data[key])
  })
  return axios({
    method: 'post',
    url,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...defines
  }).catch(() => {})
}
// export default axios