<template>
  <div class="drafts-sub-title">{{text}}</div>
</template>
<script>
  export default {
    name: 'drafts-sub-title',
    props: {
      text: String
    }
  }
</script>
<style lang="less">
.drafts-sub-title {
  line-height: 30px;
  font-size: 16px;
  padding: 20px 0 30px 20px;
  &::before {
    content: '';
    display: inline-block;
    position: relative;
    left: -4px;
    top: 3px;
    width: 4px;
    height: 18px;
    background: var(--main-color);
  }
}
</style>