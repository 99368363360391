export const ysfHost = process.env.NODE_ENV === 'development' ? '//172.16.43.86' : '//yunshenfen.cn'
export function debounceWork  (fn, delay = 200)  { 
  let timer = null
  return function() {
    if (timer !== null) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(this, arguments)
    }, delay)
  }
}

// 校验密码
export const checkPwdRegular = (rule, value, callback) => {
  if (!value) return callback(new Error('请输入密码'))
  if (!/^\S{8,16}$/.test(value)) callback(new Error('密码由8-16位数字、字母大小写、特殊符号中的两种或两种以上组成'))
  let matchingNum = 0
  // 匹配字母大小写
  if (/[a-zA-Z]+/.test(value)) matchingNum+=1
  // 匹配数字
  if (/[0-9]+/.test(value)) matchingNum+=1
  // 匹配特殊字符
  if (/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·！#￥（——）：；“”‘、，|《。》？、【】[\]]+/.test(value)) matchingNum+=1
  if (matchingNum < 2) {
    callback(new Error('密码由8-16位数字、字母大小写、特殊符号中的两种或两种以上组成'))
  }
  callback()
}


export const setTheme = (styleObject) => {
  const docStyle = document.documentElement.style
  for (let key in styleObject) {
    // eslint-disable-next-line no-prototype-builtins
    if (styleObject.hasOwnProperty(key)) {
      docStyle.setProperty(key, styleObject[key])
    }
  }
}

export const filterMoney = num => {
  if (num === null || num === undefined) return '-'
  if (Number(num) === 0) return '0.00'
  if (num) {
    if (isNaN(num)) {
      return ''
    }
    num = typeof num === 'string' ? parseFloat(num) : num // 判断是否是字符串如果是字符串转成数字
    num = num.toFixed(2) // 保留两位
    num = parseFloat(num) // 转成数字
    num = num.toLocaleString() // 转duia 成金额显示模式
    // 判断小数点位数
    const dotNum = num.split('.')[1]
    if (!dotNum) {
      num = num + '.00'
    } else if (dotNum.length === 1) {
      num = num + '0'
    }
    return num // 返回的是字符串23,245.12保留2位小数
  } else {
    return (num = null)
  }
}

const menuTypes = {
  // 业务菜单
  1: { url: '/discountForPrice', parentId: 'pay_discount' },
  2: { url: '/institutionPrice', parentId: 'pay_discount' },
  3: { url: '/recordManage', parentId: 'pay_discount' },
  4: { url: '/discountSearch', parentId: 'pay_discount' },
  // 企业用户菜单
  5: { url: '/companyMange', parentId: 'company_manage' },
  6: { url: '/userManage', parentId: 'company_manage' },
  // 用户管理菜单
  7: { url: '/customerManage', parentId: 'platform' },
  8: { url: '/authAudit', parentId: 'platform' },
  9: { url: '/customerAlter', parentId: 'platform' },
  10: { url: '/staffManage', parentId: 'platform' },
  // 运营方-业务菜单
  11: { url: '/discountData', parentId: 'pay_discount' },
  12: { url: '/discountBill', parentId: 'pay_discount' },
  
}
export const handleMenu = (list) => {
  const menus =  [
    {
      permissionId: 'company_manage',
      menuName: '企业用户', 
      url: '/enterprise',
      index: 'enterprise',
      children: []
    },
    {
      permissionId: 'pay_discount',
      menuName: '票易链', 
      url: '/discount',
      index: 'discount',
      children: []
    },
    {
      permissionId: 'platform',
      menuName: '用户管理', 
      url: '/user',
      index: 'user',
      children: []
    }
  ]
  // 处理数据
  list.forEach(item => {
    menus.forEach(menu => {
      const cur = menuTypes[item.permissionId]
      if (!cur) return
      if (menu.permissionId === cur.parentId) {
        menu.children.push({
          ...item,
          url: cur.url,
          index: cur.url.slice(1)
        })
      }
    })
  })
  // 过滤没有二级菜单的一级菜单项
  return menus.filter(menu => menu.children.length)
}



export const fileToBlob = (file, callback) => {
  // const type = file.type;
  const reader = new FileReader();
  reader.onload = function(evt) {
      // const blob = new Blob([evt.target.result], {type});
      let url= evt.target.result
      if (typeof callback === 'function') {
          callback(url)
      } else {
        console.log("url:", url);
      }
  };
  reader.readAsDataURL(file);
}