<template>
  <span :class="{'drafts-detail-image': true, 'is-text': type === 'text'}">
    <template v-if="type==='text'">
      <a>{{ src.label }}</a>
      <el-image :preview-src-list="[tenantInfo.hostName + src.src]" :src="tenantInfo.hostName + src.src">
      </el-image>
    </template>
    <template v-else>
      <img :src="tenantInfo.hostName + src"/>
      <el-image :preview-src-list="[tenantInfo.hostName + src]" :src="tenantInfo.hostName + src">
      </el-image>
    </template>
  </span>
</template>

<script>
export default {
  name: 'drafts-detail-image',
  props: {
    // type为text-展示文案-src为Object类型
    // type为img-展示图片-src为String类型
    src: [Object, String],
    type: {
      default: 'text',
      type: String
    }
  },
  mounted() {
    console.log(this.tenantInfo.hostName)
  },
}
</script>

<style lang="less" scoped>
  .drafts-detail-image {
    position: relative;
    display: flex;
    margin-bottom: 15px;
    >img {
      height: 200px;
      max-width: 100%;
    }
    .el-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    &.is-text {
      display: inline;
      margin-bottom: 0;
      +span {
        margin-left: 15px;
      }
    }
    
  }
</style>