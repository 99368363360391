<template>
  <el-table 
    :class="`drafts-table ${!multipleSelect ? 'single-select-table' : ''}`" 
    ref="Table"
    :data="data" 
    :show-summary="showSummary" 
    :summary-method="summaryMethod"
    @select="handleSelectionChange">
    <el-table-column v-if="showSelect" type="selection" width="50"></el-table-column>
    <el-table-column v-for="column in columns" :prop="column.prop" :key="column.prop" :label="column.label" :align="column.align || 'center'" :min-width="column.width  || 100">
      <template slot-scope="scope">
        <div v-if="column.type === 'slot'">
          <renderSlot :scope="scope" :render="column.render"></renderSlot>
        </div> 
        <span v-else-if="column.type === 'index'">{{scope.$index + 1}}</span>
        <span v-else-if="column.type === 'enum'">{{column.enums[scope.row[column.prop]] || '-'}}</span>
        <span v-else-if="getFormatterList(column.type)">{{getFormatterList(column.type)(scope.row[column.prop])}}</span>
        <span v-else>{{scope.row[column.prop] || '-'}}</span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import moment from 'moment'
import { filterMoney } from '@/utils/util'
// 自定义内容
const renderSlot = {
  functional: true,
  props: {
    scope: Object,
    render: Function
  },
  render: (h, data) => {
    const { scope, render } = data.props
    if (!render) return ''
    return render(scope, h)
  }
}
export default {
  name: 'drafts-table',
  data () {
    return {
      formatterList: {}
    }
  },
  components: {
    renderSlot
  },
  props: {
    summaryMethod:{default: () => {}, type: Function}, 
    showSummary: {default: false, type: Boolean},
    data: { default: () => [], type: Array },
    columns: { default: () => [], type: Array },
    multipleSelect: {
      default: true,
      type: Boolean
    },
    showSelect: {
      default: false,
      type: Boolean
    },
  },
  methods: {
    handleSelectionChange(selection) {
      // 单选判断
      if (selection.length > 1 && !this.multipleSelect) {
        this.$refs.Table.clearSelection()
        const row = selection.pop()
        this.$refs.Table.toggleRowSelection(row, true)
        return this.$emit('select', [row])
      }
      return this.$emit('select', selection)
    },
    getFormatterList (type) {
      return {
        amount: filterMoney,
        date: this.dateFormat
      }[type]
    },
    dateFormat(value, format) {
      if (value) {
        return moment(value).format(format || 'YYYY-MM-DD HH:mm:ss')
      }
      return undefined
    }
  }

}
</script>

<style lang="less">
  .drafts-table.el-table {
    border-top: 1px solid  #eee;
    border-left: 1px solid  #eee;
    // 单选时，表头的全选按钮隐藏
    &.single-select-table {
      thead .el-table-column--selection .cell {
        display: none;
      }
    }
    thead {
      font-size: 14px;
      color: #333333;
      font-weight: 400;
      th {
        line-height: 22px;
        background: #f5f7fa;
        padding: 8px 0;
        .cell {
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
    td.el-table__cell, th.el-table__cell.is-leaf {
      border-bottom-color: #eee;
      border-right:1px solid #eee;
    }
    td.el-table__cell {
      .cell {
        line-height: 22px;
      }
      padding: 8px 0 !important;
      // border-bottom: 1px solid  #f5f7fa;
    }
  }
</style>