<template>
  <div>
    <!-- 默认将所有一级菜单展开 -->
    <el-menu
      :default-active="this.$route.path.split('/')[1]"
      text-color="#303133"
      :default-openeds="['user', 'enterprise', 'discount']"
      @select="chooseMenu"
      >
      <template v-for="(item, index) in userMenus">
        <!--表示 有一级菜单-->
        <el-menu-item v-if="!item.children" :to="item.url" :key="index" :index="item.index">
          <span v-if="item.meta.title" slot="title">{{ item.menuName }}</span>
        </el-menu-item>
        <!--表示 有二级或者多级菜单 -->
        <el-submenu v-if="item.children  && item.children.length >= 1"  :index="item.index" :key="index" :style="{ width: sidebar.width + 'px' }">
          <template slot="title">
            <span slot="title" style="margin-left: 5px">{{ item.menuName }}</span>
          </template>
          <!--直接定位到子路由上，子路由也可以实现导航功能-->
          <el-menu-item v-for="(citem,cindex) in item.children" :key="cindex" :index="citem.index" style="padding-left: 50px">{{ citem.menuName }}</el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
    }
  },
  props: {
    // 自定义菜单栏
    list: {
      default: null
    }
  },
  computed: {
    ...mapGetters(['userMenus', 'sidebar'])
  },
  created () {},
  mounted () {
    console.log(this.userMenus)
  },
  methods: {
    chooseMenu(index) {
      if (this.$route.path.split('/')[1] == index) return
      this.$router.push('/' + index)
    }
  }
}
</script>

<style lang="less" scoped>

.el-menu {
  .el-menu-item, .el-submenu__title {
    height: 40px;
    line-height: 40px;
    margin-bottom: 4px;
  }
  border: none;
}
</style>
