const enterpriseRoutes = [
  {
    path: '/companyMange',
    name: '企业管理',
    meta: {
      permissionId: 5
    },
    component: () => import("@/pages/enterprise/companyManage.vue"),
  },
  {
    path: '/companyMange/detail',
    name: '企业信息变更详情',
    meta: {
      permissionId: 5
    },
    component: () => import("@/pages/enterprise/companyInfoDetail.vue"),
  },
  {
    path: '/companyMange/change',
    name: '企业信息变更',
    meta: {
      permissionId: 5
    },
    component: () => import("@/pages/enterprise/changeCompanyInfo.vue"),
  },
  {
    path: '/userManage',
    name: '用户管理',
    meta: {
      permissionId: 6
    },
    component: () => import("@/pages/enterprise/userManage.vue"),
  },
]
export default enterpriseRoutes