import _this from '@/main.js'
import {getUserInfo, getUserPermission} from '@/api/user'
import { handleMenu } from '@/utils/util'
  const common = {
    state: {
      tenantInfo: {},
      userInfo: {
        companyList: [],
        employeeName: '',
        defaultCompany: {
          companyName: ''
        }
      },
      ispageReady: false,
      userMenus: [],
      userMenuIds: [],
      currentEnterprise: ''
    },
    getters: {
      tenantInfo: state => state.tenantInfo,
      userInfo: state => state.userInfo,
      ispageReady: state => state.ispageReady,
      userMenus: state => state.userMenus,
      currentEnterprise: state => state.currentEnterprise,
    },
    mutations: {
      setTenantInfo(state, data) {
        state.tenantInfo = data
      },
      setPageReady(state, data) {
        state.ispageReady = data
      },
      setUserMenus(state, data) {
        state.userMenus = data.menus
        state.userMenuIds = data.menuIds
      },
      setCurEnterprise(state, data) {
        state.currentEnterprise = data
        window.localStorage.setItem('currentEnterprise', JSON.stringify(data))
      },
      setUserInfo(state, data) {
        state.userInfo = data
      }
    },
    actions: {
      getUserInfo:({dispatch, commit}) => {
        return new Promise((resolve) => {
          getUserInfo().then(res => {
            if (!res) return
            let defaultCompany = null
            let storedCompany = window.localStorage.getItem('currentEnterprise')
            storedCompany = storedCompany ? JSON.parse(storedCompany) : null
            res.companyList.map(item => {
              if (item.isDefault == 1) {
                defaultCompany = item
              }
              // 找到缓存中的对应企业，将值替换
              // 原因： 缓存中的auditStatus可能不是最新的
              if(storedCompany && item.companyId == storedCompany.companyId) {
                storedCompany = item
              }
            })
            // 为避免接口返回没有默认企业导致报错，当没有默认企业是，取第一条
            if (!defaultCompany) {
              defaultCompany = res.companyList[0]
            }
            // 存储默认企业
            res.defaultCompany = defaultCompany
            commit('setUserInfo', res)
            // 从登录页进入-设置默认企业为当前企业
            // 不是从登录页进来的 先判断是否有缓存数据，有则取对应的企业id的数据，没有则取默认企业
            const companyIds = res.companyList.map(item => item.companyId)
            if (storedCompany && _this.$route.path !=='login' && companyIds.includes(storedCompany.companyId)) {
              commit('setCurEnterprise', storedCompany)
            } else {
              commit('setCurEnterprise', defaultCompany)
            }
            // 获取菜单权限
            dispatch('getCompanyMenuList').then(() => {
              resolve()
            })
          })
        })
      },
      getCompanyMenuList: ({commit, state}) => {
        return new Promise(resolve => {
          getUserPermission(state.currentEnterprise.companyId).then(res => {
            // 企业用户角色存储
            commit('setCurEnterprise', {
              ...state.currentEnterprise,
              roleType: res.roleType,
              // roleUserType: res.roleUserType
            })
            // 整合菜单
            const menus = handleMenu(res.responseList)
            const menuIds = res.responseList.map(item => item.permissionId)
            commit('setUserMenus', {menus, menuIds})
            commit('setPageReady', true)
            resolve()
          })
        })
      },
    }
  }
  
  export default common
  