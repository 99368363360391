<template>
  <div class="company-setting" v-clickoutside="hidePopper">
    <p class="company-setting-text" @click="showPopper">
      {{ currentEnterprise.companyName }}
      <i class="el-icon-arrow-down"></i>
    </p>
    <transition name="el-zoom-in-top">
      <div class="company-setting-popper" v-if="visible">
        <i class="el-icon-caret-top"></i>
        <div class="company-list">
          <p @click="setCurrentCompany(userInfo.defaultCompany)">
            <span>默认企业</span>
            <i>
              {{ userInfo.defaultCompany.companyName }}
            </i>
          </p>
          <p
            v-for="item in lastCompanyList"
            :key="item.companyName"
            class="last-company"
          >
            <span @click="setDefault(item)">设为默认</span>
            <i @click="setCurrentCompany(item)">
              {{ item.companyName }}
            </i>
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { updateDefaultCompany } from "@/api/user.js";
export default {
  data() {
    return {
      visible: false,
      tab: "1",
      totalList: [],
      unreadList: [],
    };
  },
  components: {},
  computed: {
    // 过滤除当前企业外的其他企业列表
    lastCompanyList() {
      return this.userInfo.companyList.filter((item) => item.isDefault !== 1);
    },
  },
  mounted() {},
  methods: {
    setDefault(item) {
      if (item.isDefault === 1) return;
      updateDefaultCompany(item.companyId).then((res) => {
        if (!res) return;
        this.$message.success("操作成功");
        this.$store.dispatch("getUserInfo");
      });
    },
    setCurrentCompany(item) {
      if (item.companyId == this.curCompanyId) return;
      this.$store.commit("setCurEnterprise", item);
      window.location.reload();
    },
    showPopper() {
      this.visible = true;
    },
    hidePopper() {
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.company-setting {
  position: relative;
  top: 2px;
  margin-right: 20px;
  &-text {
    background: rgba(255, 255, 255, 0.16);
    cursor: pointer;
    width: 200px;
    font-size: 14px;
    // color: #fff;
    height: 32px;
    line-height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 30px;
    border-radius: 16px;
    border: 1px solid #dcdfe6;
    position: relative;
    .el-icon-arrow-down {
      position: absolute;
      right: 13px;
      top: 9px;
      color: #c0c4cc;
    }
  }
  &-popper {
    box-sizing: border-box;
    padding-top: 10px;
    z-index: 10;
    position: absolute;
    top: 56px;
    right: 50%;
    transform: translateX(50%);
    min-width: 260px;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    .el-icon-caret-top {
      color: #fff;
      font-size: 20px;
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translateX(-50%);
    }
    .company-list {
      p {
        margin: 0;
        padding: 0 20px;
        line-height: 34px;
        font-size: 14px;
        color: #000;
        cursor: pointer;
        min-width: 260px;
        span {
          margin-right: 10px;
        }
      }
      .last-company {
        border-top: 1px solid #eee;
        &:hover {
          background-color: #f5f7fa;
        }
        > span {
          color: var(--main-color);
        }
      }
    }
  }
}
</style>
<style lang="less">
// .company-setting-popper {
//   .el-tabs__active-bar {
//     background-color: #308af3;
//   }
//   .divide-line {
//     height: 1px;
//     width: 100%;
//     background: #f0f0f0;
//   }
//   .el-tabs__header {
//     width: 152px;
//     margin: 0 auto;
//     .el-tabs__item {
//       color: #333333;
//       &.is-active {
//         font-weight: 500;
//         color: #308af3;
//         outline: none;
//       }
//     }
//     .el-tabs__nav-wrap::after {
//       content: none;
//     }
//   }
// }
</style>
