<template>
  <div class="header_wrap">
    <div class="header_wrap_logo">
      <img alt="logo" :src="tenantInfo.platformLogo" />
      <span>{{ tenantInfo.platformName }}</span>
    </div>
    <div class="header_wrap_settings">
      <!-- 切换企业列表 -->
      <company-setting />
      <el-dropdown trigger="click" @command="handleCommand">
        <div class="user">
          <div>{{ userInfo.employeeName }}</div>
          <i class="el-icon-caret-bottom el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="personalInfo">个人信息</el-dropdown-item>
          <!-- <el-dropdown-item command="ysfRegister">使用云身份注册企业</el-dropdown-item> -->
          <el-dropdown-item command="register">普通注册企业</el-dropdown-item>
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import CompanySetting from "./company-setting.vue";
export default {
  name: "topHeader",
  data() {
    return {
      // cchcCom: window.CCHC_YSF_Components
    };
  },
  props: {},
  watch: {},
  components: {
    CompanySetting,
  },
  mounted() {},
  methods: {
    handleCommand(command) {
      if (command === "personalInfo") {
        this.$router.push("/personal");
      } else if (command === "register") {
        this.$router.push("/register");
      } else if (command === "logout") {
        this.userLogout();
      } else if (command === "ysfRegister") {
        // if (this.cchcCom) {
        //   this.cchcCom.showCompany(() => {
        //     console.log('回调方法')
        //   })
        // }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header_wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_settings {
    display: flex;
    height: 30px;
    align-items: center;
  }
  .header_wrap_logo {
    // color: #fff;
    display: flex;
    align-items: center;
    img {
      height: 40px;
    }
    > span {
      margin-left: 20px;
    }
  }
}
.plat_img {
  width: 226px;
  height: 28px;
}
.user {
  display: flex;
  align-items: center;
  // color: #fff;
}
.user_img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
</style>
<style lang="less">
.enterprise-select {
  width: 226px;
  margin-right: 20px;
  .el-input__suffix {
    right: 10px;
  }
  .el-input .el-select__caret {
    line-height: 28px;
    color: #fff;
  }
  .el-input__inner {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 28px;
    background: rgba(255, 255, 255, 0.16);
    color: #fff;
    border: none;
    border-radius: 14px;
    padding: 0 38px 0 20px;
  }
  &-popper {
    &.el-popper {
      margin-top: 18px;
    }
    .popper__arrow {
      display: none;
    }
    .el-select-dropdown__item {
      color: #000;
      font-size: 14px;
      &.selected {
        color: #308af3;
      }
    }
  }
}
</style>
