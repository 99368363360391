import Vue from 'vue'
import Vuex from 'vuex'
import menu from './modules/menu'
import common from './modules/common'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    menu,
    common
  }
})

export default store