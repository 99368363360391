<template>
  <el-container v-if="ispageReady">
    <el-header>
      <top-header></top-header>
    </el-header>
    <el-container>
      <el-aside
        v-if="!$route.meta.disableMenu"
        :style="{ width: sidebar.width + 'px' }"
      >
        <!-- <el-aside :style="{ width: sidebar.width + 'px' }"> -->
        <left-menu></left-menu>
      </el-aside>
      <el-main>
        <bread></bread>
        <!-- 暂时注释 -->
        <el-card v-if="noPermission">
          <div
            style="
              color: #999;
              text-align: center;
              font-size: 20px;
              line-height: 500px;
            "
          >
            暂无权限
          </div>
        </el-card>
        <el-card v-else shadow="never">
          <router-view />
        </el-card>
        <!-- <el-card shadow="never">
          <router-view />
        </el-card> -->
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapGetters } from "vuex";
import TopHeader from "./topHeader";
import Bread from "./bread";
import LeftMenu from "./leftMenu.vue";

export default {
  data() {
    return {
      noPermission: false,
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },
  components: {
    TopHeader,
    Bread,
    LeftMenu,
  },
  watch: {
    ispageReady(val) {
      val && this.judgePermission();
    },
    $route() {
      this.judgePermission();
      // this.goIndexPage()
    },
  },
  created() {
    // noInit 表示，通过登录登录跳转进来的页面，不需要再次获取用户信息
    if (this.$route.meta.noInit) return;
    this.$store.dispatch("getUserInfo").then(() => {
      this.goIndexPage();
    });
  },
  methods: {
    goIndexPage() {
      /**
       * auditStatus
       * 0-未完善信息 跳转到-完善信息页
       * 1-待审核 跳转到-待审核提示页
       * 2-审核成功 跳转到-贴现询价页
       * 3-审核失败 跳转到-完善信息页
       */
      const { auditStatus, roleType } = this.currentEnterprise;
      // 切换企业时，无权限，跳转对应功能页
      if (this.noPermission) {
        // 运营方跳转贴现数据
        // 企业方跳转贴现询价
        if (roleType >= 3) return this.$router.push("/discountData");
        else return this.$router.push("/discountForPrice");
      }
      if (roleType >= 3) return;
      if (auditStatus == 2) {
        if (
          ~window.location.hash.indexOf("auditStatus") ||
          ~window.location.hash.indexOf("completeInfo")
        ) {
          this.$router.push("/discountForPrice");
        }
        return;
      } else if (auditStatus == 1) {
        this.$router.push("/auditStatus");
      } else {
        this.$router.push("/completeInfo");
      }
    },
    judgePermission() {
      const menuIds = this.$store.state.common.userMenuIds;
      const permissionId = this.$route.meta.permissionId;
      // 判断：有权限id, 且菜单权限中没有该id，则提示无权限
      if (permissionId && !menuIds.includes(permissionId)) {
        this.noPermission = true;
      } else {
        this.noPermission = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.el-container {
  min-width: 1140px;
}
.el-aside {
  min-height: calc(100vh - 60px);
}
// .el-header {
// background:var(--main-color);
// }
.el-card__body,
.el-main {
  padding: 12px;
}
.el-main {
  background-color: #f0f2f5;
  color: #333333;
  padding-bottom: 0;
}
.el-card {
  border-radius: 0;
  min-height: calc(100vh - 80px);
}
</style>
