
// const types = {
//   // HANDLE_LEFT_MENU: 'HANDLE_LEFT_MENU', // 收缩左侧菜单
//   // SET_LEFT_COLLAPSE: 'SET_LEFT_COLLAPSE' // 改变左边菜单的收缩宽度
// }
const menu = {
  state: {
    sidebar: {
      opened: true,
      width: 200
    },
  },
  getters: {
    sidebar: state => state.sidebar,
  },
  mutations: {
  },
  actions: {
  }
}

export default menu
