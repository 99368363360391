<template>
  <transition name="fade">
    <div class="myLoading-box">
      <div class="myLoading-box-inner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  data () {
    return {}
  }
}
</script>
<style lang="less" scoped>
  .myLoading-box {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background:rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  .myLoading-box-inner {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    animation: rotateLoading 1.5s linear infinite;
    >div {
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color:var(--main-color);
      &:nth-child(1) {
        top: 0;
        left: 50%;
        opacity: 0.8;
        transform: translateX(-50%);
      }
      &:nth-child(2) {
        top: 7px;
        left: 41px;
        opacity: 0.7;
      }
      &:nth-child(3) {
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.6;
      }
      &:nth-child(4) {
        top: 41px;
        left: 41px;
        opacity: 0.5;
      }
      &:nth-child(5) {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.4;
      }
      &:nth-child(6) {
        top: 41px;
        left: 7px;
        opacity: 0.3;
      }
      &:nth-child(7) {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &:nth-child(8) {
        top: 7px;
        left: 7px;
        opacity: 0.9;
      }
    }
  }
  @keyframes rotateLoading {
    0% {
      transform: translate(-50%, -50%) rotate(0deg) ;
    }
    50% {
      transform: translate(-50%, -50%) rotate(180deg) ;
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg) ;
    }
  }
</style>
