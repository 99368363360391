import Vue from "vue";
import store from '@/store'
import VueRouter from "vue-router";
import userRoute from './user'
import loginRoute from './login'
import discountRoute from './discount'
import enterpriseRoute from './enterprise'


Vue.use(VueRouter);

const router = new VueRouter({
  base: "",
  // mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: '/personal',
      name: '个人信息',
      meta: {
        disableMenu: true
      },
      component: () => import("@/pages/user/personal/index"),
    },
    {
      path: '/ysfCreate',
      name: '使用云身份注册',
      meta: {
        disableMenu: true
      },
      component: () => ("@/pages/user/ysfCreate/index")
    }
    
    
  ].concat(loginRoute, userRoute, discountRoute, enterpriseRoute)
});

router.beforeEach((to, from, next) => {
  if (from.path === '/login') {
    to.meta.noInit = true
  }
  // // 路由发生变化修改页面title
  // if (to.name) {
  //   document.title = `${to.name} - 票易链`;
  // }
  next();
});

export default router;
